'use client'

import React from 'react'
import { SessionProvider } from 'next-auth/react'

import { Session } from '@fsg/next-auth/types'

export interface AuthContextProps {
  children: React.ReactNode
  session: Session | null | undefined
}

export function AuthContext({ children, session }: AuthContextProps) {
  return <SessionProvider session={session}>{children}</SessionProvider>
}
